<style scoped>
.nav-link {
  font-size: 1.2rem;
  color: black;
}
</style>
<template>
  <div class="topBar textSt p-3">
    <ul class="nav justify-content-end">
      <li class="nav-item dropdown textSt">
        <a class="nav-link dropdown-toggle textSt" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
          <i v-if="theme == 'dark-mode'" class="bi bi-moon-stars-fill pe-2 textSt"></i>
          <i v-if="theme == 'light-mode'" class="bi bi-brightness-high-fill pe-2 textSt"></i>
          <i v-if="theme == themeClient" class="bi bi-award-fill pe-2 textSt"></i>
        </a>
        <ul class="dropdown-menu textLt">
          <li @click="toggleTheme('dark-mode')"><a class="dropdown-item textSt" href="#"> <i class="bi bi-moon-stars-fill pe-2 textSt"></i>  Modo nocturno</a></li>
          <li @click="toggleTheme('light-mode')"><a class="dropdown-item textSt" href="#"> <i class="bi bi-brightness-high-fill pe-2 textSt"></i>  Modo dia</a></li>
          <li  @click="toggleTheme(themeClient)"><a class="dropdown-item textSt" href="#"> <i class="bi bi-award-fill pe-2 textSt"></i>  Modo personalizado</a></li>
        </ul>
      </li>
      <li class="nav-item">
        <div class="nav-link">
          <i class="bi bi-bell textSt"></i>
        </div>
      </li>
      <li class="nav-item">
        <div class="nav-link">
          <i class="bi bi-chat textSt"></i>
        </div>
      </li>
      <li class="nav-item dropdown textSt">
        <div class="nav-link dropdown-toggle textSt" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
          <i class="bi bi-person-circle pe-2 textSt"></i>
          <span>{{ user.name }}</span>
        </div>

        <ul class="dropdown-menu textLt">
         
          <li><a class="dropdown-item textSt" href="#">Reportar Error</a></li>
          <li><a class="dropdown-item textSt" href="#">Configuracion</a></li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li><a class="dropdown-item textSt" @click="logOut()">Cerrar Session</a></li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      theme: localStorage.getItem('theme') || 'light-mode',
      themeClient: '',
      currentUrl: '',
      user: {
        name: '',
        id_client: ''
      }
    }
  },
  computed: {
     
  },
  mounted() {
    document.body.className = this.theme;
    this.currentUrl = window.location.pathname;
   
    let sessionData = localStorage.getItem('user');
    this.user =  JSON.parse(sessionData);
    this.themeClient = this.user.company.skin;
    
    //this.isLogin();
  },
  methods: {
    logOut() {
      localStorage.removeItem('accessToken');
      window.location.href = './../../api/auth/logout'
    },
    toggleTheme(theme) {
      this.theme = theme;
      document.body.className = this.theme;
      localStorage.setItem('theme', this.theme);
    },
  },
};
</script>