<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
         <!-- Componente de la barra de navegación -->
         <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed" @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3" v-if="edit_mode"><b>Editar usuario</b></h2>
                        <h2 class="fw-normal mb-4 me-3" v-else>          <b>Crear usuario</b></h2>
                    </div>
                    <div class="row p-4">
                        <div class="col-6 mb-4">
                            <label for="rut" class="form-label">Rut</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.rut.err }" id="rut" placeholder=""
                                v-model="dataForm.rut.value" @blur="handlerBlur">
                            <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un
                                rut válido</span>
                        </div>
                        <div class="col-6 mb-4">
                            <label for="email" class="form-label">Correo</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.email.err }" id="email" placeholder=""
                                v-model="dataForm.email.value" @blur="handlerBlur">
                            <span v-if="dataForm.email.err" class="text-danger">Debe ingresar un
                                correo electrónico válido</span>
                        </div>
                        <div class="col-6 mb-4">
                            <label for="firstName" class="form-label">Nombre</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.firstName.err }" id="firstName" placeholder=""
                                v-model="dataForm.firstName.value" @blur="handlerBlur">
                            <span v-if="dataForm.firstName.err" class="text-danger">Debe ingresar un
                                nombre</span>
                        </div>
                        <div class="col-6 mb-4">
                            <label for="lastName" class="form-label">Apellido</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.lastName.err }" id="lastName" placeholder=""
                                v-model="dataForm.lastName.value" @blur="handlerBlur">
                            <span v-if="dataForm.lastName.err" class="text-danger">Debe ingresar un
                                nombre</span>
                        </div>
                        
                        <div class="col-6 mb-4">
                            <label for="rol" class="form-label">Credencial</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.rol.value">
                                <option v-for="rol in roles" :value="rol.id_rol">{{ rol.name }}</option>
                            </select>
                            <span v-if="dataForm.rol.err" class="text-danger">Debe asignar un rol</span>
                        </div>
                    </div>
                    <div class="text-center text-lg-end mt-4 pt-2">

                        <template v-if="edit_mode">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="createOrEditUser()"  >
                                Guardar
                            </button>
                        </template>
                        <template v-else>
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="createOrEditUser()">
                                Crear
                            </button>
                        </template>

                        <a class="btn btn-md text-white bg-danger" 
                        style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                        href="/usuarios">Cancelar</a>

                    </div>

                </div>
            </div>
        </main>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned:localStorage.getItem('pinned') == 'false'?false:true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            roles: [

            ],
            idClient:0,
            dataForm: {
                sending: false,
                firstName: {
                    value: '',
                    err: false
                },
                lastName: {
                    value: '',
                    err: false
                },
                email: {
                    value: '',
                    err: false
                },
                rol: {
                    value: '',
                    err: false
                },
                rut: {
                    value: '',
                    err: false,
                }
            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        this.getRol();
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.id_company = this.user.company.id_company;
        let urlpath = window.location.pathname;
        let parts = urlpath.split('/');
        this.edit_mode = false;
        this.edit_id = 0;
        this.usuario = {};
        if (parts.length == 4) {
            if (!isNaN(Number(parts[3]))) {
                this.edit_mode = true;
                this.edit_id = Number(parts[3]);
                this.getUser(this.edit_id);
            }
        }
        
        //console.log(this);
       
    },
    computed: {

    },
    methods: {
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },

        // obtener datos del usuario para
        // edición
        getUser(id_user) {
            this.$swal({
                title: 'Cargando...'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/users/' + id_user, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {

                console.log(response.data)
                if (response.status == 200) {
                    self.usuario = response.data;
                    self.dataForm.firstName.value = self.usuario['name'];
                    self.dataForm.lastName.value = self.usuario['last_name'];
                    self.dataForm.email.value = self.usuario['email'];
                    
                    self.dataForm.rol.value =  self.usuario['user_rol'][0]['id_rol'];
                    self.dataForm.rut.value = self.usuario['rut'];
                } else {
                    self.usuario = {};
                }
            }).catch(function (error) {

                console.log(error);

                if (error.response.statusText) {
                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                    }
                    if (error.response.status == 404) {
                        self.$swal.fire({
                            icon: "error",
                            title: "Usuario no encontrado",
                            showConfirmButton: true,
                            showCancelButton: false
                            });
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error interno al obtener datos de usuario",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: true,
                            showCancelButton: false
                            });
                    }
                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error obteniendo datos de usuario",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                        });
                }
            });
        },
        // Obtener roles (permisos)
        // para llenar el select 
        getRol() {
            this.$swal({
                title: 'Cargando...'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/roles', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.roles = response.data;

                    this.$swal.close();
                } else {
                    self.roles = [];
                }
            }).catch(function (error) {

                console.log(error);

                if (error.response.statusText) {
                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout';
                    } else {
                        self.$swal.fire({
                        icon: "error",
                        title: "Error procesando roles disponibles (permisos)",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                        });
                    }

                } else {
                    self.$swal.fire({
                        icon: "error",
                        title: "Error obteniendo roles disponibles  (permisos)",
                        text: "Comuníquese con soporte.",
                        showConfirmButton: false,
                        showCancelButton: false
                        });
                }
            });
        },
        // Llamar /api/user-rols para establecer rol
        // del usuario
        setRol(id_user, id_rol, ok_callback) {
            
            const self = this;

            let posturl = '/api/user-rols';

            if (self.edit_mode) {
                let id_user_rol = self.usuario['user_rol'][0]['id_user_rol'];
                posturl = '/api/user-rols/' + id_user_rol + '?_method=PATCH';
            }

            axios.post(posturl,
                {
                    id_user: id_user, 
                    id_rol: id_rol
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {

                    //console.log(response.data)
                    if (response.status == 201 || response.status == 200) {
                        // console.log(response);
                        ok_callback();

                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error al asignar rol (permisos)",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: true,
                            showCancelButton: false
                            });
                    }
                }).catch(function (error) {
                    console.log(error);

                    if (error.response.statusText) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        }
                    } else {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error asignando rol (permisos)",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: true,
                            showCancelButton: false
                            });
                    }
                });
            
        },
        validateEmail(email) {
            // Expresión regular para validar el formato de correo electrónico
            const emailPattern = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
            return emailPattern.test(email);
        },

        validaRut(rut) {
            if (typeof rut !== 'string') {
                return false
            }
            if (!rut.includes("-")){
                return false
            }
            if (!/^([1-9]\d{0,2}(\.?\d{3})*)-?[\dkK]$/.test(rut)) {
                return false
            }
            rut = typeof rut === 'string'
                ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
                : ''
            let t = parseInt(rut.slice(0, -1), 10)
            let m = 0
            let s = 1
            while (t > 0) {
                s = (s + (t % 10) * (9 - (m++ % 6))) % 11
                t = Math.floor(t / 10)
            }
            const v = s > 0 ? '' + (s - 1) : 'K'
            return v === rut.slice(-1)
        },
        preformatRut(rut) { 
            if (rut.length < 2){
                return rut;
            }
            if (rut.includes("-")) {
                return rut;
            } else {
                return [rut.slice(0, -1), "-", rut.slice(-1)].join('');
            }
        },
        formatRut(rut, withDots) { 
            let parts = rut.split('-');
            let nums = parts[0].replaceAll('.', '');
            if (withDots) {
                let rutnum = new Intl.NumberFormat('es-CL').format(nums);
                return rutnum + "-" + parts[1];
            } else {
                return nums + "-" + parts[1];
            }
        },

        // Crear un nuevo usuario
        // y asignarle un rol (permisos)
        createOrEditUser() {
            const self = this;
            // console.log(self);
            // console.log(self.formData);
            self.dataForm.sending = true;

            let errFound = false;

            if (self.dataForm.firstName.value == '') {
                self.dataForm.firstName.err = true;
                errFound = true;
            } else {
                self.dataForm.firstName.err = false;
            }
            
            if (self.dataForm.email.value == '') {
                self.dataForm.email.err = true;
                errFound = true;
            } else {
                if (!self.validateEmail(self.dataForm.email.value)) {
                    self.dataForm.email.err = true;
                    errFound = true;
                    //return false;
                } else {
                    self.dataForm.email.err = false;
                }
            }

            self.dataForm.rut.value = self.preformatRut(self.dataForm.rut.value)
            if (!self.validaRut(self.dataForm.rut.value)) {
                self.dataForm.rut.err = true;
                errFound = true;
            } else {
                self.dataForm.rut.value = self.formatRut(self.dataForm.rut.value, true);
                self.dataForm.rut.err = false;
            }

            if (self.dataForm.rol.value == '') {
                self.dataForm.rol.err = true;
                errFound = true;
            } else {
                self.dataForm.rol.err = false;
            }

            if (errFound) {
                return false
            }

            let chmsg = 'Creando usuario...';

            if (this.edit_mode) {
                chmsg = 'Editando usuario...' // para editar
            }

            this.$swal({
                title: chmsg
            });
            this.$swal.showLoading();

            let urlpost = '/api/users' // para crear
            if (this.edit_mode) {
                urlpost = '/api/users/' + this.edit_id  + '?_method=PATCH' // para editar
            }

            axios.post(urlpost,
                {
                    name: self.dataForm.firstName.value, 
                    last_name: self.dataForm.lastName.value,
                    email: self.dataForm.email.value,
                    id_company: self.id_company,
                    rut: self.formatRut(self.dataForm.rut.value, false)
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    //console.log(response.data)
                    // this.$swal.close();
                    if (response.status == 201) {

                        self.setRol(response.data.id_user,
                                    self.dataForm.rol.value,
                                    function() {
                                        self.$swal({
                                            title: "Usuario agregado exitosamente",
                                            showDenyButton: false,
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                        }).then((result) => {
                                            /* Read more about isConfirmed, isDenied below */
                                            window.location.href = '/usuarios';
                                        })
                                    }
                        );

                        
                    
                    } else if (response.status == 200) {
                        self.setRol(response.data.id_user,
                                    self.dataForm.rol.value,
                                    function() {
                                        self.$swal({
                                            title: "Usuario editado exitosamente",
                                            showDenyButton: false,
                                            showCancelButton: false,
                                            showConfirmButton: true,
                                        }).then((result) => {
                                            /* Read more about isConfirmed, isDenied below */
                                            window.location.href = '/usuarios';
                                        })
                                    } 
                        );
                    
                    } else {

                        self.$swal.fire({
                            icon: "error",
                            title: "Error al crear usuario",
                            text: "Comuníquese con soporte.",
                            showConfirmButton: true,
                            showCancelButton: false
                            });
                    }
                }).catch(function (error) {

                    console.log(error);

                    if (error.response.statusText) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        } else {
                            self.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Problema al crear usuario detectado, comuníquese con soporte.",
                                showConfirmButton: true,
                                showCancelButton: false
                            });
                        }

                    } else {

                        this.$swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "Problema interno detectado, comuníquese con soporte.",
                            showConfirmButton: true,
                            showCancelButton: false
                        });

                    }
                });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>