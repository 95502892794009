<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed" @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="custom-title">Servicios por cobrar</h2>
                    <p>Servicios que serán cobrados al cliente por la gestión de los despachos.</p>
                    <div class="text-end">
                        <a class="btn btn-success" href="serviciosCobrar/crear">agregar servicio por cobrar</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay servicios por cobrar</h2>

                </div>
                <div v-if="items.length > 0" >
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #amount="data">
                            <span>{{data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.initials) : 0 }}</span>
                        </template>
                        <template #value_payment="data">
                            <span>{{data.value.value_payment > 0 ? formatCurrency(data.value.value_payment, data.value.value_payment_initials) : 0 }}</span>
                          
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount, data.value.value_unpaid_initials) : 0 }}</span>
                        </template>
                        <template #actions="data">
                            <div class=" form-switch">
                                acciones
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>

</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            theme: localStorage.getItem('theme') || 'light-mode',
            collapsed: true,
            pinned:localStorage.getItem('pinned') == 'false'?false:true || false,
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            items: [

            ],
            columns: [
                { field: "shipping_number", title: "Nro despacho" },
                { field: "date_fee", title: "Fecha" },
                { field: "week_number", title: "Numero de semana" },
                { field: "bill_number", title: "Factura" },
                { field: "rut", title: "Rut cliente" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto deuda total" },
                { field: "value_payment", title: "Monto pagado" },
                { field: "unpaid_amount", title: "Monto no pagado" },
                { field: "bank_account_origin", title: "Cuenta de origen" },
                { field: "bank", title: "Banco" },
                { field: "payment_method", title: "Metodo de pago" }
            ]
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {
            return currencyCode + ' '+ new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo servicios por cobrar'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/services-to-be-collected', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';



.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>