<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Crear nota de cobro</b></h2>
                        <p>Una nota de cobro contiene un listado de pagos a proveedor (gastos) que serán enviados
                            como cobranza a un cliente. </p>
                    </div>
                    <div class="row p-4">
                        <div class="col-3 mb-4">
                            <label for="numberBill" class="form-label">Número de documento</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.numberBill.err }" id="numberBill" placeholder=""
                                v-model="dataForm.numberBill.value" @blur="handlerBlur">
                            <span v-if="dataForm.numberBill.err" class="text-danger">Debe ingresar un numero de nota de
                                cobro</span>
                        </div>
                        <div class="col-3 mb-4">
                            <label for="client" class="form-label">Cliente</label>
                            <select @change="clientSelected($event)" class="form-select"
                                aria-label="Default select example" v-model="dataForm.client.value">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-3 mb-4">
                            <label for="date_payment" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date_payment.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                        </div>

                        <div class="col-3 mb-4">
                            <label for="billing_note_amount" class="form-label">Monto</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.billing_note_amount.err }" id="billing_note_amount"
                                placeholder="" v-model="dataForm.billing_note_amount.value" @blur="handlerBlur">
                            <span v-if="dataForm.billing_note_amount.err" class="text-danger">Debe ingresar un
                                monto</span>
                        </div>
                        <div class="col-3 mb-4">
                            <label for="currency" class="form-label">Divisa</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currency.value">
                                <option v-for="currency in currencies" :value="currency.id_currency">{{ currency.name }}
                                </option>
                            </select>
                        </div>




                    </div>
                    <div class="my-3">
                        <div class="col-sm-12 text-end">
                            <button type="button" class="btn btn-primary mb-3" @click="verModal = true">Asociar más
                                pagos a proveedor</button>
                        </div>
                        <h6 class="text-black">Pagos a proveedor asociados</h6>
                        <div class="card w-100">

                            <div class="card-body">
                                <div class="row" style="border-bottom: 1px #5a50e0 solid;">
                                    <div class="col-sm-3">
                                        <label for="id" class="form-label">Nro despacho</label>
                                    </div>

                                    <div class="col-sm-2">
                                        <label for="date_payment" class="form-label">Fecha creación/último abono</label>
                                    </div>

                                    <div class="col-sm-2">
                                        <label for="value_payment" class="form-label">Monto deuda original</label>
                                    </div>

                                    <div class="col-sm-2">
                                        <label for="unpaid_amount" class="form-label">Monto impago</label>
                                    </div>

                                    <div class="col-sm-2">
                                        <label for="value_payment_initials" class="form-label">Divisa</label>
                                    </div>

                                    <div class="col-sm-1 py-1">
                                        <label for="">Accion</label>
                                    </div>
                                </div>

                                <div class="row g-1 mt-3" v-for="(bill, ind) in selectedItems">
                                    <div class="col-sm-3">
                                        <p class="" id="id" :value="bill.shipping_number" v-html="bill.shipping_number">
                                        </p>
                                    </div>

                                    <div class="col-sm-2">
                                        <p class="" id="date_payment" :value="bill.date_payment"
                                            v-html="bill.date_payment"></p>
                                    </div>

                                    <div class="col-sm-2">
                                        <p class="" id="value_payment" :value="bill.value_payment"
                                            v-html="bill.value_payment"></p>
                                    </div>

                                    <div class="col-sm-2">
                                        <p class="" id="unpaid_amount" :value="bill.unpaid_amount"
                                            v-html="bill.unpaid_amount"></p>
                                    </div>

                                    <div class="col-sm-2">
                                        <p class="" id="value_payment_initials" :value="bill.value_payment_initials"
                                            v-html="bill.value_payment_initials"></p>
                                    </div>
                                    

                                    <div class="col-sm-1 py-1">
                                        <button @click="toggleSelection(bill)" class="mt-2 bg-danger text-white">
                                            <i class="bi bi-x-circle-fill  h6"></i>
                                            Quitar
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="text-center text-lg-end mt-4 pt-2">
                        <button type="button" class="btn btn-md text-white mx-2"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                            @click="create(true)">
                            Crear y emitir
                        </button>
                        <button type="button" class="btn btn-md text-white mx-2 bg-success"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;" @click="create(false)">
                            Guardar sin emitir
                        </button>
                        <button type="button" class="btn btn-md text-white bg-danger"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;">
                            Cancelar
                        </button>
                    </div>

                </div>
            </div>
        </main>

        <!-- Modal ar -->
        <div class="modal-mb" v-if="verModal">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Pagos a proveedor</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">
                                <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                                    :sortColumn="'date_payment'">
                                    <template #select="data">
                                        <button @click="toggleSelection(data.value)">
                                            {{ isSelected(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>

                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            verModal: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            selectedItems: new Set(),
            items: [

            ],
            clients: [

            ],
            columns: [
                { field: 'select', title: 'Seleccionar' },
                { field: "shipping_number", title: "Nro despacho" },
                { field: "date_payment", title: "Fecha" },
                { field: "week_number", title: "Numero de semana" },
                { field: "concept", title: "Concepto" },
                { field: "value_payment", title: "Monto deuda original" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "value_payment_initials", title: "Divisa" }
            ],
            idClient: 0,

            dataForm: {
                sending: false,
                numberBill: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                clientName: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                clientRut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                date_payment: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                rol: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                currency: {
                    value: 15,
                    err: false,
                },
                billing_note_amount: {
                    value: '',
                    err: false,
                },
                unpaid_amount: {
                    value: '',
                    err: false,
                },
                value_payment: {
                    value: '',
                    err: false,
                },
                client: {
                    value: '',
                    err: false,
                }
            }
        }
    },
    mounted() {
        this.getClients();
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        this.idClient = this.user.company.id;
        this.getCurrencies();
        // this.getAR()
    },
    computed: {
        isSelected() {
            // Computed para validar si el elemento está seleccionado
            return (value) => this.selectedItems.has(value);
        }
    },
    methods: {

        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        toggleSelection(value) {
            const self = this
            self.verModal = false;
            if (self.selectedItems.has(value)) {
                self.selectedItems.delete(value); // Elimina el valor si está seleccionado
            } else {
                self.selectedItems.add(value); // Agrega el valor si no está seleccionado
                self.verModal = true;
            }
            // Aseguramos que Vue detecte el cambio
            self.selectedItems = new Set(self.selectedItems);
        },

        closeModal() {
            const self = this
            self.verModal = false;
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo pago a proveedores'
            });
            this.$swal.showLoading();
            const self = this;
            let req = {
                page: 1,
                per_page: 50,
                id_client: self.dataForm.client.value,
                only_last_not_paid: true
            }

            axios.post('/api/getSupplierPayments', req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        create(isEmit) {
            const self = this;

            self.dataForm.sending = true;

            this.$swal({
                title: 'Creando nota de cobro...'
            });
            let args = Array.from(this.selectedItems);
           
            this.$swal.showLoading();
            const req = { form: self.dataForm, selectPass: args, isEmit: isEmit}

            axios.post('/api/createBillingNote',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "note de cobro guardada exitosamente",
                            showConfirmButton: true,
                            confirmButtonText: "ok",
                            showCancelButton: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '../notascobro'
                            }
                        });
                     
                        
                    } else {

                    }
                }).catch(function (error) {

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });

        },

        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.clients = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },

        clientSelected(value) {
            //console.log('Selected Option:', value);
            this.selectedOption = value;
            const self = this;
            self.getAR();
        },

    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>