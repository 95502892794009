<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Registrar Pago a proveedor</b></h2>
                        <p>Agregar un pago a un proveedor al sistema junto a su correspondiente factura, asociada a un despacho.
                        </p>
                    </div>
                    <div class="row pt-4 px-1">
                        <div class="col-md-3 mb-4">
                            <label for="date" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker :format="dateFormat" v-model="dataForm.date.value" locale="cl" >
                                </VueDatePicker>
                            </div>
                            <span v-if="dataForm.date.err" class="text-danger">Debe seleccionar una fecha</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="mount" class="form-label">Monto</label>
                            <input type="number" class="form-control" :class="{ 'border-danger': dataForm.mount.err }"
                                id="mount" placeholder="" v-model="dataForm.mount.value" @blur="handlerBlur">
                            <span v-if="dataForm.mount.err" class="text-danger">Debe ingresar un
                                monto</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="currenci" class="form-label">Divisa</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currenci.value">
                                <option v-for="currenci in currencies" :value="currenci.id_currency">{{ currenci.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.currenci.err" class="text-danger">Debe seleccionar una divisa</span>
                            
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="reference" class="form-label">Referencia</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.reference.err }"
                                id="reference" placeholder="" v-model="dataForm.reference.value" @blur="handlerBlur">
                            <span v-if="dataForm.reference.err" class="text-danger">Debe ingresar una
                                referencia</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="concept" class="form-label">Concepto</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.concept.value">
                                <option v-for="concept in concepts" :value="concept.id_concept">{{ concept.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.concept.err" class="text-danger">Debe seleccionar un concepto</span>
                        </div>

                        <div class="col-3 mb-4">
                            <label for="client" class="form-label">Cliente</label>
                            <select  class="form-select"
                                aria-label="Default select example" v-model="dataForm.client.value">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.client.err" class="text-danger">Debe seleccionar un cliente</span>
                        </div>

                        <!-- 
                        <div class="col-3 mb-4">
                            <label for="payStatus" class="form-label">Estado</label>
                            <select  class="form-select"
                                aria-label="Default select example" v-model="dataForm.payStatus.value">
                                <option v-for="pstatus in allPayStatus" :value="pstatus.id_payment_status">{{ pstatus.name }}
                                </option>
                            </select>
                            <span v-if="dataForm.payStatus.err" class="text-danger">Debe seleccionar un estado</span>
                        </div>
                        -->

                        <!-- 
                        <div class="col-md-7 px-2 mx-3 row p-3 text-center" style='border:2px solid black'>
                            <div class="col-md-6 pt-1" v-if="dataForm.isSupply">

                                <p>Este pago sera descontado de una provision</p>
                            </div>
                            <div class="col-md-6 pt-1">
                                <button type="button" class="btn btn-primary" @click="verClientSupply = true">Descontar desde una
                                    provision del cliente</button>
                            </div>
                        </div>
                        -->
                    </div>
                    <hr>
                    <p>Adjuntar factura o documento que corresponda</p>
                    <div class="row">
                        <div class="col-md-3 mb-4">
                            <label for="documentNumber" class="form-label">Numero Documento</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm2.documentNumber.err }" id="documentNumber"
                                placeholder="" v-model="dataForm2.documentNumber.value" @blur="handlerBlur">
                            <span v-if="dataForm2.documentNumber.err" class="text-danger">Debe ingresar un numero de
                                documento</span>
                        </div>

                        <div class="col-md-4 mb-4">
                            <label for="documentType" class="form-label">Tipo Documento</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm2.documentType.value">
                                <option v-for="dtetype in dtetypes" :value="dtetype.id_dte_type">{{ dtetype.name }}
                                </option>
                            </select>
                        </div>


                        <div class="col-md-3 mb-4">
                            <label for="companyService" class="form-label">Empresa que emitio el servicio</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm2.companyService.err }" id="companyService"
                                placeholder="" v-model="dataForm2.companyService.value" @blur="handlerBlur">
                            <span v-if="dataForm2.companyService.err" class="text-danger">Debe ingresar una
                                empresa</span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="value-cot" class="form-label mb-0">Adjuntar Archivos</label>
                            <DropFile :files="dataForm2.files" />
                            <div class="col-12 mt-4">
                                <div v-for="file in dataForm2.files">

                                    <i class="bi bi-file-check-fill"></i>
                                    <span class="text-black">
                                        {{ file.name }}
                                    </span>
                                    <i class="bi bi-trash px-2 text-danger" @click="deleteFile(file)"></i>
                                </div>
                            </div>
                            <span v-if="dataForm2.filerr" class="text-danger">
                                Debe adjuntar un documento</span>

                        </div>

                    </div>
                    <hr>
                    <p>Asociar despachos</p>
                    
                    <div class="row">
                        <div class="col-md-6 px-2 mx-3 row p-3 text-center" style='border:2px solid black'>
                            <div class="col-md-6" v-if="dataForm3.isShipping">
                                <p>Este pago esta asociado a un despacho: {{ dataForm3.shippingSelect.shipping_number }}
                                </p>
                            </div>
                            <div class="col-md-6 pt-3">
                                <button type="button" class="btn btn-primary" @click="verShipping = true">Ver
                                    despachos</button>
                            </div>
                            
                        </div>
                        <span v-if="dataForm3.errship" class="text-danger">
                            Debe asociar un despacho</span>
                        
                        <!--
                        <div class="col-md-5 px-2 mx-3 row p-3 text-center" style='border:2px solid black'>
                            
                            <div class="col-md-6" v-if="dataForm3.isBillingNote">

                                <p>Este pago esta asociado a una nota de cobro: {{
            dataForm3.billingNoteSelect.billing_note_number }}</p>
                            </div>
                            <div class="col-md-6 pt-3">
                                <button type="button" class="btn btn-primary" @click="verBillNote = true">Ver
                                    notas de cobros</button>
                            </div>
                            
                        </div>
                        -->
                        <div class="text-center text-lg-end mt-4 pt-2">
                            <button type="button" class="btn btn-md text-white mx-2"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                                @click="create()">
                                Crear
                            </button>
                            <button type="button" class="btn btn-md text-white bg-danger"
                                style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                                Cancelar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </main>


        <!-- Modal client supply-->
        <div class="modal-mb" v-if="verClientSupply">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Provisiones</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">
                                <vue3-datatable :search="search" :rows="supply" :columns="columns" order
                                    :sortable="true" :sortColumn="'date'">
                                    <template #select="data">
                                        <button @click="toggleSelectionSupply(data.value)">
                                            {{ isSelectedSupp(data.value) ? 'Deseleccionar' : 'Seleccionar' }}
                                        </button>
                                    </template>
                                    <template #amount="data">
                                        <p>{{ data.value.amount }} <strong>{{ data.value.initials }}</strong></p>
                                    </template>
                                    <template #client="data">
                                        <p>{{ data.value.client.name }} </p>
                                    </template>
                                    <template #currency="data">
                                        <p>{{ data.value.currency.initials }}</p>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

        <!-- Modal shipping-->
        <div class="modal-mb" v-if="verShipping">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Despachos</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal2()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">
                                <vue3-datatable :search="search" :rows="shippings" :columns="columns2" order
                                    :sortable="true" :sortColumn="'date'">
                                    <template #select="data">
                                        <button @click="toggleSelectionShipping(data.value)">
                                            {{ isSelectedShipp(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                    <template #amount="data">
                                        <p>{{ data.value.amount }} <strong>{{ data.value.initials }}</strong></p>
                                    </template>
                                    <template #client="data">
                                        <p>{{ data.value.client.name }} </p>
                                    </template>
                                    <template #currency="data">
                                        <p>{{ data.value.currency.initials }}</p>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal2()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

        <!-- Modal bill note-->
        <div class="modal-mb" v-if="verBillNote">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Nota de cobro</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal3()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">
                                <vue3-datatable :search="search" :rows="billNotes" :columns="columns3" order
                                    :sortable="true" :sortColumn="'date'">
                                    <template #select="data">
                                        <button @click="toggleSelectionBill(data.value)">
                                            {{ isSelectedBill(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                    <template #amount="data">
                                        <p>{{ data.value.amount }} <strong>{{ data.value.initials }}</strong></p>
                                    </template>
                                    <template #client="data">
                                        <p>{{ data.value.client.name }} </p>
                                    </template>
                                    <template #currency="data">
                                        <p>{{ data.value.currency.initials }}</p>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal3()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../../component/NavBar.vue';
import BarTop from './../../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DropFile,
        DataTable,
        DataTablesCore,
        NavBar,
        BarTop,
        Vue3Datatable
    },
    data() {
        return {
            dateFormat: "dd/MM/yyyy",
            collapsed: true,
            verClientSupply: false,
            verShipping: false,
            shippings: [],
            verBillNote: false,
            billNotes: [],
            supply: [],
            clients: [],
            columns: [
                { field: 'select', title: 'Seleccionar' },
                { field: "id_clients_supply", title: "Identificador" },
                { field: "client", title: "Cliente" },
                { field: "items_supply", title: "Item" },
                { field: "amount", title: "Monto Total" },
                { field: "currency", title: "Moneda" },
            ], columns2: [
                { field: 'select', title: 'Seleccionar' },
                { field: "shipping_number", title: "Numero Despacho" },
                { field: "date_shipping", title: "Fecha" },
                { field: "week", title: "Semana" },
                { field: "boarding_number", title: "Numero de embarque" },
                { field: "booking_number", title: "Numero de reserva" },
                { field: "type_operation", title: "Tipo operacion" },
                { field: "consignee", title: "Despachador" },
                { field: "port_departure_shipment", title: "Puerto de embarque" },
                { field: "country_destination", title: "Pais destino" }
            ],
            columns3: [
                { field: 'select', title: 'Seleccionar' },
                { field: "billing_note_number", title: "Nro Nota de cobro" },
                { field: "emit_date", title: "Fecha de emisión" },
                { field: "created_at", title: "Fecha creación" },
                { field: "rut", title: "Rut cliente" },
                { field: "name", title: "Nombre cliente" },
                { field: "initials", title: "Divisa" },
                { field: "emit_amount", title: "Monto" }
            ],
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            paymentMethodes: [],
            banks: [],
            concepts: [],
            dtetypes: [],
            allPayStatus: [],
            // idClient: 0,
            dataForm3: {
                isBillingNote: false,
                isShipping: false,
                errship: false,
                shippingSelect: {},
                billingNoteSelect: {}
            },
            dataForm2: {
                documentType: {
                    value: 110,
                    err: false,
                },
                documentNumber: {
                    value: '',
                    err: false,
                },
                companyService: {
                    value: '',
                    err: false,
                },
                filerr: false,
                files: []
            },
            dataForm: {
                sending: false,
                isSupply: false,
                supplySelect: {},
                client: {
                    value: '',
                    err: false,
                },
                payStatus: {
                    value: '',
                    err: false,
                }, 
                date: {
                    value: null,
                    err: false,
                },
                mount: {
                    value: '',
                    err: false,

                },
                currenci: {
                    value: 15,
                    err: false,

                },
                reference: {
                    value: '',
                    err: false,

                },
                concept: {
                    value: 1,
                    err: false,

                },

            }
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        // this.idClient = this.user.id_company;
        this.getBanks();
        // this.getSupply();
        this.getDteTypes();
        this.getShipping();
        this.getConcepts();
        this.getBillNotes();
        this.getCurrencies();
        this.getClients();
        // TODO implementar selección de estado
        //this.getAllPayStatus();
        this.paymentMethodes = [{
            id: 1,
            name: 'Transferencia electronica',

        },
        {
            id: 2,
            value: 'Pago tarjeta de credito',

        }, {
            id: 3,
            value: 'Pago en efectivo',

        }]
    },
    computed: {

    },
    methods: {
        isSelectedBill(data) {
            return this.dataForm3.billingNoteSelectSelect == data
        },
        isSelectedShipp(data) {
            return this.dataForm3.shippingSelect == data
        },
        isSelectedSupp(data){
            return this.dataForm.supplySelect == data
        },
        toggleSelectionSupply(data){
            if (this.dataForm.supplySelect == data) {
                this.dataForm.isSupply = false;
                this.dataForm.supplySelect = {};
            } else {
                this.dataForm.isSupply = true;
                this.dataForm.supplySelect = data;
            }
        },
        toggleSelectionBill(data) {
            
            if (this.dataForm3.billingNoteSelect == data) {
                this.dataForm3.isBillingNote = false;
                this.dataForm3.billingNoteSelect = {};
            } else {
                this.dataForm3.isBillingNote = true;
                this.dataForm3.billingNoteSelect = data;
            }

        },
        toggleSelectionShipping(data) {

            if (this.dataForm3.shippingSelect == data) {
                this.dataForm3.isShipping = false;
                this.dataForm3.shippingSelect = {};
            } else {
                this.dataForm3.isShipping = true;
                this.dataForm3.shippingSelect = data;
            }

        },

        async getDteTypes() {
            const self = this;

            axios.get('/api/dtetypes', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                //console.clear();
                //console.table(response.data);
                if (response.status == 200) {
                    self.dtetypes = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.dtetypes = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        },

        async getConcepts() {
            try {
                const response = await axios.get('/api/concept', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.concepts = response.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },
        async getBillNotes() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getBillingNotes', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                
                if (response.status == 200) {

                    self.billNotes = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getShipping() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
               
                if (response.status == 200) {

                    self.shippings = response.data.data

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.shippings = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getSupply() {
            const self = this;
            try {
                const response = await axios.get('/api/client-supplies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });

                self.supply = response.data;
               
            } catch (error) {
                console.error('Error fetching supply:', error);
            }
        },

        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.clients = response.data.filter((client)=>client.status==1);

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },

        async getBanks() {
            try {
                const response = await axios.get('/api/banks', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.banks = response.data;
            } catch (error) {
                console.error('Error fetching banks:', error);
            }
        },
        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },

        async getAllPayStatus() {
            try {
                const response = await axios.get('/api/payments-status', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.allPayStatus = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },

        closeModal() {
            const self = this;
            self.verClientSupply = false;
        },
        closeModal2() {
            const self = this;
            self.verShipping = false;
        },
        closeModal3() {
            const self = this;
            self.verBillNote = false;
        },
        
        updateCollapsed(value) {
          
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
        
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
          
        },
        create() {
            const self = this;
         
            
            self.dataForm.sending = true;

            let foundError = false;

            if (!self.dataForm.date.value || self.dataForm.date.value == '') {
                self.dataForm.date.err = true;
                foundError = true;
            } else {
                self.dataForm.date.err = false;
            }

            if (self.dataForm.mount.value == '') {
                self.dataForm.mount.err = true;
                foundError = true;
            } else {
                self.dataForm.mount.err = false;
            }
            
            if (self.dataForm.client.value == '') {
                self.dataForm.client.err = true;
                foundError = true;
            } else {
                self.dataForm.client.err = false;
            }

            // TODO implementar selección de estado
            /*if (self.dataForm.payStatus.value == '') {
                self.dataForm.payStatus.err = true;
                foundError = true;
            } else {
                self.dataForm.payStatus.err = false;
            }*/

            if (self.dataForm.reference.value == '') {
                self.dataForm.reference.err = true;
                foundError = true;
            } else {
                self.dataForm.reference.err = false;
            }

            if (self.dataForm2.documentNumber.value == '') {
                self.dataForm2.documentNumber.err = true;
                foundError = true;
            } else {
                self.dataForm2.documentNumber.err = false;
            }

            if (self.dataForm2.companyService.value == '') {
                self.dataForm2.companyService.err = true;
                foundError = true;
            } else {
                self.dataForm2.companyService.err = false;
            }

            if (self.dataForm2.files.length < 1) {
                self.dataForm2.filerr = true;
                foundError = true;
            } else {
                self.dataForm2.filerr = false;
            }

            if (!self.dataForm3.isShipping) { 
                self.dataForm3.errship = true;
                /*self.$swal.fire({
                            icon: "danger",
                            title: "Debe asociar un despacho",
                            showConfirmButton: false,
                            showCancelButton: false
                });*/
                foundError = true;
            } else {
                self.dataForm3.errship = false;
            }

            if (foundError) {
                return false;
            }


            this.$swal({
                title: 'Registrando pago...'
            });
            this.$swal.showLoading();
            let req = { form: self.dataForm, form2: this.dataForm2, form3: this.dataForm3 }

            axios.post('/api/createSupplierPayment',
                req,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    // this.$swal.close();

                    self.$swal.fire({
                            icon: "success",
                            title: "Pago registrado exitosamente",
                            showConfirmButton: true,
                            showCancelButton: false
                    }).then((result) => {
                        location.reload();
                    });

                    
                }).catch(function (error) {

                    if (error.status == 400) {
                        self.$swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Número de documento ya usado en sistema",
                            showConfirmButton: false,
                            showCancelButton: false
                            });
                    }

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>