<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="custom-title">Despachos</h2>
                    <p>Listar todos los despachos.</p>
                    <div class="text-end">
                        <a class="btn btn-success" href="despachos/crear">agregar despachos</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay despachos</h2>
                </div>
                <div v-if="items.length > 0">
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #amount="data">
                            <span>{{data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.initials) : 0 }}</span>
                           
                        </template>
                        <template #value_payment="data">
                            <span>{{data.value.value_payment > 0 ? formatCurrency(data.value.value_payment, data.value.value_payment_initials) : 0 }}</span>
                          
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount, data.value.value_unpaid_initials) : 0 }}</span>
                        </template>
                        <template #accion="data">
                            <div class="form-switch">
                                <a @click="editPackage(data.value.id_shipping)">
                                    <i class="bi bi-box px-2"></i>
                                </a>
                                <a @click="editShipping(data.value.id_shipping)">
                                    <i class="bi bi-pencil px-2"></i>
                                </a>
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>

import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
       
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            items: [

            ],
            columns: [
                { field: "shipping_number", title: "Numero Despacho" },
                { field: "date_shipping", title: "Fecha" },
                { field: "week", title: "Semana" },
                { field: "boarding_number", title: "Numero de embarque" },
                { field: "booking_number", title: "Numero de reserva" },
                { field: "type_operation", title: "Tipo operacion" },
                { field: "consignee", title: "Despachador" },
                { field: "aduana", title: "Aduana" },
                { field: "vessel", title: "Veesel" },
                { field: "consignee", title: "Consignatario" },
                { field: "bailment", title: "Comodato" },
                { field: "port_departure_shipment", title: "Puerto de embarque" },
                { field: "country_destination", title: "Pais destino" },
                { field: "accion", title: "Acciones" },
            ]
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {
            return currencyCode + ' '+ new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        editPackage(id){
            window.location.href = '/despachos/package/' + id;
        },
        editShipping(id){
            window.location.href = '/despachos/edit/' + id;
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo cuentas por cobrar'
            });
            this.$swal.showLoading();
            const self = this;
            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>