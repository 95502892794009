<template>
    <div class="main">
        <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" multiple name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.xlsb,.docx,.dotx,.xlsx,.xlsm" total-max-size="2048" />

            <label for="fileInput" class="file-label">
                <div v-if="isDragging">Suelta los archivos que quieres adjuntar.</div>
                <div v-else>Arrastra un archivo o <u>click aqui</u> para subir.</div>
            </label>
        </div>
    </div>
</template>
  
<script>

export default {
    
    props: {
        files: Array
    },
    data() {
        return {
            isDragging: false,
            
        };
    },
    mounted(){
        console.log(this.$props);
    },
    methods: {
        onChange() {
            this.$props.files.push(...this.$refs.file.files);
            console.log(this.$props.files);
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
    },
};
</script>
<style scoped>
.main {
    display: flex;
    flex-grow: 1;
}

.dropzone-container {
    padding: 0.6rem;
    background: #f7fafc;
    border: 1px solid #e2e8f0;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 17px;
    display: block;
    cursor: pointer;
}

.preview-container {
    display: flex;
    margin-top: 2rem;
}

.preview-card {
    display: flex;
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
}

.preview-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
}
</style>