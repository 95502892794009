<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.modal-mb {
    position: fixed;
    background: #00000094;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-dialo {
    width: 80vw;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="" style="padding: 4rem;">
                <div class="py-4">
                    <div>
                        <h2 class="fw-normal mb-4 me-3"><b>Crear cobro por servicio</b></h2>
                    </div>
                    <div class="row pt-4 px-1">
                        <div class="col-md-3 mb-4">
                            <label for="date" class="form-label">Fecha</label>
                            <div class="input-group mb-3">
                                <VueDatePicker v-model="dataForm.date.value" locale="es" format="dd/MM/yyyy">
                                </VueDatePicker>
                            </div>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="amount" class="form-label">Monto</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                                id="amount" placeholder="" v-model="dataForm.amount.value" @blur="handlerBlur">
                            <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un
                                monto</span>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="currency" class="form-label">Divisa</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.currency.value">
                                <option v-for="currency in currencies" :value="currency.id_currency">{{ currency.name }}
                                </option>
                            </select>
                        </div>
                        

                        <div class="col-md-3 mb-4">
                            <label for="concept" class="form-label">Concepto</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.concept.value">
                                <option v-for="concept in concepts" :value="concept.name">{{ concept.name }}
                                </option>
                            </select>
                        </div>

                        <hr>

                        <div class="col-3 mb-4">
                            <label for="client" class="form-label">Cliente</label>
                            <select  class="form-select"
                                aria-label="Default select example" v-model="dataForm.client.value">
                                <option v-for="client in clients" :value="client.id_client">{{ client.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-4">
                            <label for="bill" class="form-label">Número de factura</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.bill.err }"
                                id="bill" placeholder="" v-model="dataForm.bill.value" @blur="handlerBlur">
                            <span v-if="dataForm.bill.err" class="text-danger">Debe ingresar un numero factura</span>
                        </div>
                        <div class="col-md-6 mb-4">
                            <label for="value-cot" class="form-label mb-0">Adjuntar factura</label>
                            <DropFile :files="dataForm.files" />
                            <div class="col-12 mt-4">
                                <div v-for="file in dataForm.files">

                                    <i class="bi bi-file-check-fill"></i>
                                    <span class="text-black">
                                        {{ file.name }}
                                    </span>
                                    <i class="bi bi-trash px-2 text-danger" @click="deleteFile(file)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 px-2 mx-3 row p-3 text-center" style='border:2px solid black'>
                            <!--
                            <div class="col-md-6">
                                <input class="form-check-input mt-0" type="checkbox" v-model="dataForm.isShipping"
                                    aria-label="Checkbox for following text input">
                                <p>Este pago esta asociado a un despacho</p>
                            </div>
                            -->
                            <div class="col-md-6 pt-3">
                                <button type="button" @click="verShipping = true" class="btn btn-primary">seleccionar
                                    despachos</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center text-lg-end mt-4 pt-2">
                        <button type="button" class="btn btn-md text-white mx-2"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;"
                            @click="create()">
                            Crear
                        </button>
                        <button type="button" class="btn btn-md text-white bg-danger"
                            style="padding-left: 2.5rem; padding-right: 2.5rem;background-color: #5a50e0;">
                            Cancelar
                        </button>
                    </div>

                </div>
            </div>
        </main>

        <!-- Modal shipping-->
        <div class="modal-mb" v-if="verShipping">
            <div class="modal-dialo bg-white">
                <div class="modal-content">
                    <div class="modal-header row">
                        <h5 class="modal-title text-black col-11" id="exampleModalLabel">Despachos</h5>
                        <button type="button" class="btn-close col" data-bs-dismiss="modal" @click="closeModal()"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="card">
                            <div class="">
                                <vue3-datatable :search="search" :rows="shippings" :columns="columns" order
                                    :sortable="true" :sortColumn="'date'">
                                    <template #select="data">
                                        <button @click="toggleSelectionShipping(data.value)">
                                            {{ isSelectedShipp(data.value) ? 'Desasociar' : 'Asociar' }}
                                        </button>
                                    </template>
                                    <template #amount="data">
                                        <p>{{ data.value.amount }} <strong>{{ data.value.initials }}</strong></p>
                                    </template>
                                    <template #client="data">
                                        <p>{{ data.value.client.name }} </p>
                                    </template>
                                    <template #currency="data">
                                        <p>{{ data.value.currency.initials }}</p>
                                    </template>
                                </vue3-datatable>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-3">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="closeModal()">Cerrar</button>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import DropFile from './../../component/DropFile.vue';
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import NavBar from './../../component/NavBar.vue';
import BarTop from './../../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";

DataTable.use(DataTablesCore);

export default {
    components: {
        VueDatePicker,
        DataTable,
        VueDatePicker,
        DropFile,
        DataTablesCore,
        NavBar,
        BarTop,
        Vue3Datatable
    },
    data() {
        return {
            collapsed: true,
            verShipping: false,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            currencies: [],
            concepts: [],
            clients: [],
            paymentMethodes: [
                {
                    id: 1,
                    name: 'Transferencia electronica',

                },
                {
                    id: 2,
                    value: 'Pago tarjeta de credito',

                }, {
                    id: 3,
                    value: 'Pago en efectivo',

                },
            ],
            banks: [],
            //idClient: 0,
            dataForm: {
                sending: false,
                client: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                date: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                amount: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                currency: {
                    value: 15,
                    err: false,
                    msjErr: ''
                },
                concept: {
                    value: 1,
                    err: false,
                    msjErr: ''
                },
                bill: {
                    value: '',
                    err: false,
                    msjErr: ''
                },

                isShipping: false,
                files:[]
            },
            shippings: [

            ],
            dataForm3: {
                isShipping: false,
                shippingSelect: {},
            },
            columns: [
                { field: 'select', title: 'Seleccionar' },
                { field: "shipping_number", title: "Numero Despacho" },
                { field: "date_shipping", title: "Fecha" },
                { field: "week", title: "Semana" },
                { field: "boarding_number", title: "Numero de embarque" },
                { field: "booking_number", title: "Numero de reserva" },
                { field: "type_operation", title: "Tipo operacion" },
                { field: "consignee", title: "Despachador" },
                { field: "port_departure_shipment", title: "Puerto de embarque" },
                { field: "country_destination", title: "Pais destino" }
            ],
        }
    },
    mounted() {
        document.body.className = this.theme;
        let sessionData = localStorage.getItem('user');
        this.user = JSON.parse(sessionData);
        //this.idClient = this.user.id_company;
        this.getShipping();
        this.getCurrencies();
        this.getConcepts();
        this.getClients();
    },
    computed: {

    },
    methods: {
        isSelectedShipp(data) {
            return this.dataForm3.shippingSelect == data
        },

        toggleSelectionShipping(data) {

            if (this.dataForm3.shippingSelect == data) {
                this.dataForm3.isShipping = false;
                this.dataForm3.shippingSelect = {};
            } else {
                this.dataForm3.isShipping = true;
                this.dataForm3.shippingSelect = data;
            }

        },

        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.clients = response.data;

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });

        },

        async getConcepts() {
            try {
                const response = await axios.get('/api/concept', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.concepts = response.data;
            } catch (error) {
                console.error('Error fetching concepts:', error);
            }
        },

        async getShipping() {
            const self = this;

            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getShippings', req, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.shippings = response.data.data
                } else {
                    self.shippings = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });
        },
        async getCurrencies() {
            try {
                const response = await axios.get('/api/currencies', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                this.currencies = response.data;
            } catch (error) {
                console.error('Error fetching currencies:', error);
            }
        },
        closeModal() {
            const self = this;
            self.verShipping = false;
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;
            console.log(id);
        },
        create() {
            const self = this;
            // console.log(self);
            // console.log(self.formData);
            self.dataForm.sending = true;
            if (self.dataForm.date.value == '') {
                self.dataForm.date.msjErr = 'Seleccione una fecha'
                self.dataForm.date.err = true;
                return false;
            }
            if (self.dataForm.amount.value == '') {
                self.dataForm.amount.msjErr = 'Ingrese un monto'
                self.dataForm.amount.err = true;
                return false;
            }
            if (self.dataForm.bill.value == '') {
                self.dataForm.bill.msjErr = 'Ingrese el número de factura'
                self.dataForm.bill.err = true;
                return false;
            }
            if (self.dataForm.currency.value == '') {
                self.dataForm.currency.msjErr = 'Seleccione una divisa'
                self.dataForm.currency.err = true;
                return false;
            }

            if (self.dataForm.concept.value == '') {
                self.dataForm.concept.msjErr = 'Seleccione una divisa'
                self.dataForm.concept.err = true;
                return false;
            }

            if (self.dataForm.client.value == '') {
                self.dataForm.client.msjErr = 'Seleccione un cliente'
                self.dataForm.client.err = true;
                return false;
            }


            this.$swal({
                title: 'Ingresando cobro por servicio...'
            });
            this.$swal.showLoading();

            self.thefile = null;
            if (self.dataForm.files.length > 0) {
                self.thefile = self.dataForm.files[0];
            }

            self.id_shipping = null;
            if(self.dataForm3.isShipping) {
                self.id_shipping = self.dataForm3.shippingSelect["id_shipping"];
            }

            axios.postForm('/api/cs-fees',
                {
                    date_fee: self.dataForm.date.value,
                    amount: self.dataForm.amount.value,
                    id_currency: self.dataForm.currency.value,
                    concept:  self.dataForm.concept.value,
                    bill_number:  self.dataForm.bill.value,
                    file:  self.thefile,
                    id_cs_fee_status: 1,
                    id_client: self.dataForm.client.value,
                    id_shipping: self.id_shipping
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    
                    self.$swal.fire({
                            icon: "success",
                            title: "Cobro por servicio registrado exitosamente",
                            showConfirmButton: true,
                            showCancelButton: false
                    }).then((result) => {
                        location.reload();
                    });
                }).catch(function (error) {
                    self.$swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Error interno detectado, comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                            });

                    if (error.response.statusText == 'Unauthorized') {
                        localStorage.removeItem('accessToken');
                        window.location.href = './../../api/auth/logout'
                    }
                });


        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>