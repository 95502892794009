<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.bh-table-responsive input[type=checkbox] {
    width: 2rem;
    height: 1rem;
    opacity: 1;
    position: inherit;
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="p-5 pt-2">
                <div class="py-4">
                    <h2 class="custom-title">Lista de clientes</h2>
                    <div class="text-end">
                        <a class="btn btn-success" href="clientes/create">agregar cliente</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay clientes ingresados</h2>
                </div>
                <div v-if="items.length > 0">
                    <div class="mb-5">
                        <input v-model="search" type="text" class="form-input max-w-xs"
                            placeholder="Buscar cliente..." />
                    </div>

                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'ID'">
                        <template #id_client="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.id_client }}
                            </div>
                        </template>

                        <template #name="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.name }}
                            </div>
                        </template>

                        <template #rut="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.rut }}
                            </div>
                        </template>

                        <template #email="data" >
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                {{ data.value.email }}
                            </div>
                        </template>

                        <template #status="data">
                            <div v-bind:class = "(data.value.status != 1)?'text-danger':'text-dark'">
                                <strong>{{ data.value.status == 1 ? 'habilitado' : 'deshabilitado' }}</strong>
                            </div>
                        </template>
                        
                        <template #actions="data">
                            <div class=" form-switch">
                                <a class="btn" @click="editarClient(data.value.id_client)">
                                    <i class="bi bi-pencil px-2"></i>
                                </a>
                                <a class="btn" @click="clientSupplies(data.value.id_client)">
                                    <i class="bi bi-cash px-2"></i>
                                </a>
                                <a class="btn button" v-if="data.value.status == 1"
                                    @click="disableClient(data.value.id_client)">
                                    ⛔

                                </a>
                                <a class="btn button" v-else @click="enableClient(data.value.id_client)">
                                    🟢
                                </a>

                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>

</template>
<script>

import { ref } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";

import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
export default {
    components: {
        Vue3Datatable,
        NavBar,
        BarTop,
        ref
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            search: '',
            items: [

            ],
            clientesSelect: [],
            columns: [
                { field: "id_client", title: "ID", filter: false },
                { field: "name", title: "NOMBRE" },
                { field: "rut", title: "RUT" },
                { field: "email", title: "CORREO" },
                { field: "status", title: "ESTADO" },
                { field: 'actions', title: 'ACCION', sortable: false },

            ]
        }
    },
    mounted() {
        this.getClients()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        editarClient(id_client) {
            window.location.href = 'clientes/create/' + id_client;
        },

        formatRut(rut, withDots) { 
            let parts = rut.split('-');
            let nums = parts[0].replaceAll('.', '');
            if (withDots) {
                let rutnum = new Intl.NumberFormat('es-CL').format(nums);
                return rutnum + "-" + parts[1];
            } else {
                return nums + "-" + parts[1];
            }
        },

        clientSupplies(id_client){
            window.location.href = 'clientes/supplies/' + id_client;
        },
        changeClientStatus(id_client, id_status, msg, msg_error) {
            const self = this;

            let urlpost = '/api/clients/' + id_client + '?_method=PATCH' // para editar

            axios.post(urlpost,
                {
                    status: id_status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                }).then(response => {
                    //console.log(response.data)
                    self.$swal.close();
                    if (response.status == 200) {
                        ;
                        self.$swal.fire({
                            title: msg,
                            icon: "info",
                            showCancelButton: false,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Ok"
                        }).then((result) => {
                            this.getClients();
                        });

                    } else {
                        console.log(response.data);

                        self.$swal.fire({
                            icon: "error",
                            title: msg_error,
                            text: "Comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                }).catch(function (error) {
                    self.$swal.close();

                    if (error.response.statusText) {
                        if (error.response.statusText == 'Unauthorized') {
                            localStorage.removeItem('accessToken');
                            window.location.href = './../../api/auth/logout'
                        } else {
                            //console.log(error.response.statusText);
                            console.log(error.response.data);
                            //console.log(error.response.data.errors);

                            self.$swal.fire({
                                icon: "error",
                                title: "Error",
                                text: "Problema modificando cliente detectado, comuníquese con soporte.",
                                showConfirmButton: false,
                                showCancelButton: false
                            });
                        }
                    } else {
                        console.log(error);
                        self.$swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Problema interno detectado, comuníquese con soporte.",
                            showConfirmButton: false,
                            showCancelButton: false
                        });

                    }
                });
        },

        disableClient(id_client) {
            const self = this;

            this.$swal.fire({
                title: "¿Está seguro que desea deshabilitar este cliente?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, deshabilitar"
            }).then((result) => {
                if (result.isConfirmed) {
                    self.changeClientStatus(id_client,
                        2,
                        "cliente deshabilitado exitosamente",
                        "error al deshabilitar cliente");
                }
            });
        },


        enableClient(id_client) {
            const self = this;

            this.$swal.fire({
                title: "¿Está seguro que desea habilitar este cliente?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, habilitar"
            }).then((result) => {
                if (result.isConfirmed) {
                    self.changeClientStatus(id_client,
                        1,
                        "cliente habilitado exitosamente",
                        "error al habilitar cliente");
                }
            });
        },

        getClients() {
            this.$swal({
                title: 'Obteniendo clientes'
            });
            this.$swal.showLoading();
            const self = this;

            axios.get('/api/clients', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {
                    self.items = response.data.map((client)=>{
                        client.rut = self.formatRut(client.rut, true)
                        return client
                    });

                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.items = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }
            });
        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>