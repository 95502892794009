<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="custom-title">Cuentas por cobrar</h2>
                    <p>Listar todos los pagos a proveedores y servicios pendientes de pago.
                        Los pagos son los realizados a aduanas, navieras y administradores varios para efectuar los
                        despachos.
                        Los servicios son cobros por la gestión de los despachos.</p>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay cuentas por cobrar</h2>

                </div>
                <div v-if="items.length > 0">
                    <p>Filtros</p>
                    <div class="m-3 row">
                        <div class="col-md-2 mb-4">
                            <label for="rut" class="form-label">Rut cliente</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.rut.err }"
                                id="rut" placeholder="" v-model="dataForm.rut.value">
                            <span v-if="dataForm.rut.err" class="text-danger">Debe ingresar un
                                rut</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="shipping_number" class="form-label">Numero despacho</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.shipping_number.err }" id="shipping_number"
                                placeholder="" v-model="dataForm.shipping_number.value">
                            <span v-if="dataForm.shipping_number.err" class="text-danger">Debe ingresar un
                                numero de despacho</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="document_number" class="form-label">Numero de factura</label>
                            <input type="text" class="form-control"
                                :class="{ 'border-danger': dataForm.document_number.err }" id="document_number"
                                placeholder="" v-model="dataForm.document_number.value">
                            <span v-if="dataForm.document_number.err" class="text-danger">Debe ingresar un
                                numero de documento</span>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="document_type" class="form-label">Tipo</label>
                            <select class="form-select" aria-label="Default select example"
                                v-model="dataForm.document_type.value">
                                <option :value="1">Pago proveedor</option>
                                <option :value="2">Servicio por cobrar</option>
                            </select>
                        </div>
                        <div class="col-md-2 mb-4">
                            <label for="amount" class="form-label">Monto</label>
                            <input type="text" class="form-control" :class="{ 'border-danger': dataForm.amount.err }"
                                id="amount" placeholder="" v-model="dataForm.amount.value">
                            <span v-if="dataForm.amount.err" class="text-danger">Debe ingresar un monto</span>
                        </div>
                        <div class="col-md-2 mt-4">
                            <div class="text-end">
                                <a class="btn btn-success" @click="filter()">Filtrar</a>
                            </div>
                        </div>
                    </div>
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #amount="data">
                            <span>{{data.value.amount > 0 ? formatCurrency(data.value.amount, data.value.initials) : 0 }}</span>
                        </template>
                        <template #value_payment="data">
                            <span>{{data.value.value_payment > 0 ? formatCurrency(data.value.value_payment, data.value.initials) : 0 }}</span>
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount, data.value.initials) : 0 }}</span>
                        </template>
                        <template #actions="data">
                            <div class=" form-switch">
                                acciones
                            </div>
                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';

export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            dataForm: {
                rut: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                shipping_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                document_number: {
                    value: '',
                    err: false,
                    msjErr: ''
                },
                document_type: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
                amount: {
                    value: 0,
                    err: false,
                    msjErr: ''
                },
            },
            currentItems: [],
            items: [],
            columns: [
                { field: "shipping_number", title: "Numero Despacho" },
                { field: "date", title: "Fecha" },
                { field: "week_number", title: "Semana" },
                { field: "id_billing_note", title: "Nota de Cobro" },
                { field: "bill_number", title: "Numero de Factura" },
                { field: "table_type", title: "Tipo" },
                { field: "rut", title: "Rut" },
                { field: "concept", title: "Concepto" },
                { field: "amount", title: "Monto Total" },
                { field: "value_payment", title: "Monto Abonado" },
                { field: "unpaid_amount", title: "Monto Impago" },
                { field: "bank", title: "Banco" },
                { field: "bank_account_origin", title: "Cuenta origen" },
                { field: "payment_method", title: "Metodo de Pago" }
            ]
        }
    },
    mounted() {
        this.getAR()
        document.body.className = this.theme;

    },
    computed: {

    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {
            return currencyCode + ' '+ new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {

            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {

            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        filter() {
            const self = this;
            self.items = self.currentItems.filter(item => {
                console.log()
                let tipo = this.dataForm.document_type.value == 1 ? 'SERVICIO POR COBRAR':'PAGO A PROVEEDOR';
                if(this.dataForm.rut.value && !item.rut.includes(this.dataForm.rut.value)) return false;
                if (this.dataForm.document_type.value && !item.table_type.includes(tipo)) return false;
                if  (this.dataForm.document_type.value && !item.table_type.includes(tipo)) return false
                if  (this.dataForm.document_number.value && !item.numeroDocumento.includes(this.dataForm.document_number.value)) return false
                if  (this.dataForm.amount.value && !item.amount.includes(this.dataForm.amount.value)) return false
                if (this.dataForm.shipping_number.value && !item.shipping_number.includes(this.dataForm.shipping_number.value)) return false
                return true;
            })
            
        },
        getAR() {
            this.$swal({
                title: 'Obteniendo cuentas por cobrar'
            });
            this.$swal.showLoading();
            const self = this;
            axios.get('/api/getReceivables', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    self.currentItems = self.items;
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';

body {
    background-color: #f2f5f9;
}

.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>