<style>
.list-group-item2 {
    position: relative;
    /* display: block; */
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    color: var(--bs-list-group-color);
    text-decoration: none;
    background-color: var(--bs-list-group-bg);
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
</style>
<template>
    <div class="app-layout">
        <!-- Componente de la barra de navegación -->
        <NavBar :collapsed="collapsed" :pinned="pinned" @update:collapsed="updateCollapsed"
            @update:pinned="updatePinned" />
        <!-- Contenido principal -->

        <main :class="{ 'main-collapsed': collapsed, 'main-expanded': !collapsed || pinned }">
            <BarTop />
            <div class="pt-2 px-3">
                <div class="py-4">
                    <h2 class="custom-title">Pago a proveedores</h2>
                    <p>Estos pagos son los realizados a aduanas, navieras y administradores varios para efectuar los
                        despachos.
                        Se usan para calcular cuánto se le cobrará al cliente final y para crear las notas
                        de cobro.</p>
                    <div class="text-end">
                        <a class="btn btn-success" href="pagoProveedores/crear">agregar pago a proveedor</a>
                    </div>
                </div>
                <div v-if="items.length == 0" class="text-center">
                    <h2 class="mt-5">No hay pagos a proveedores</h2>

                </div>
                <div v-if="items.length > 0">
                    <vue3-datatable :search="search" :rows="items" :columns="columns" order :sortable="true"
                        :sortColumn="'date'">
                        <template #value_payment="data">
                            <span>{{data.value.value_payment > 0 ? formatCurrency(data.value.value_payment, data.value.value_payment_initials) : 0 }}</span>
                           
                        </template>
                        <template #value_payment_exchanged="data">
                            <span>{{data.value.value_payment_exchanged > 0 ? formatCurrency(data.value.value_payment_exchanged, data.value.value_payment_initials) : 0 }}</span>
                        </template>
                        <template #unpaid_amount="data">
                            <span>{{data.value.unpaid_amount > 0 ? formatCurrency(data.value.unpaid_amount, data.value.value_payment_initials) : 0 }}</span>
                        </template>
                        <template #actions="data">

                            <template v-if="(data.value.id_payment_status == 4 || data.value.id_payment_status == 5)">
                                <p></p>
                            </template>
                            <template v-else>
                                <div class=" form-switch">
                                    <button @click="addPartialPayment(data.value)">
                                        Abonar
                                    </button>
                                    <button @click="createBill(data.value)">
                                        Crear Nota de cobro
                                    </button>
                                </div>
                            </template>

                        </template>
                    </vue3-datatable>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import NavBar from './../component/NavBar.vue';
import BarTop from './../component/BarTop.vue';
import Vue3Datatable from "@bhplugin/vue3-datatable";

export default {
    components: {
        VueDatePicker,
        Vue3Datatable,
        NavBar,
        BarTop
    },
    data() {
        return {
            collapsed: true,
            pinned: localStorage.getItem('pinned') == 'false' ? false : true || false,
            theme: localStorage.getItem('theme') || 'light-mode',
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
            items: [

            ],
            columns: [
                { field: "shipping_number", title: "Nro despacho" },
                { field: "last_date_change", title: "Fecha" },
                { field: "last_week_change", title: "Numero de semana" },
                { field: "id_billing_note", title: "Nota cobro" },
                { field: "rut", title: "Rut cliente" },
                { field: "concept", title: "Concepto" },
                { field: "value_payment", title: "Monto deuda original" },
                { field: "value_payment_exchanged", title: "Monto abonado" },
                { field: "unpaid_amount", title: "Monto impago" },
                { field: "bank_account_origin", title: "Cuenta de origen" },
                { field: "bank", title: "Banco" },
                { field: "payment_method", title: "Metodo de pago" },
                { field: "actions", title: "Acciones" },
            ]
        }
    },
    mounted() {
        this.getSP()
        document.body.className = this.theme;
    },
    computed: {

    },
    methods: {
        formatCurrency(value, currencyCode = 'CLP') {
            return currencyCode + ' '+ new Intl.NumberFormat('es-CL', {
                style: 'currency',
                currency: currencyCode,
                minimumFractionDigits: 0 // O puedes cambiar el valor según lo que necesites
            }).format(value);
        },
        updateCollapsed(value) {
            console.log('Evento recibido:', value); // Para depuración
            this.collapsed = value; // Actualiza el estado
        },
        updatePinned(value) {
            console.log('Evento recibido pinn:', value); // Para depuración
            this.pinned = value; // Actualiza el estado
        },
        showreq(id) {
            const self = this;

        },
        addPartialPayment(data) {
            this.$swal({
                title: "Deseas abonar este pago?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "abonar",
                denyButtonText: `abonar desde provisiones`
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = 'pago/abonar/' + data.id_payment;
                } else if (result.isDenied) {
                    window.location.href = 'pago/provisionabonar/' + data.id_payment;
                }
            });
            
        },
        getSP() {
            this.$swal({
                title: 'Obteniendo pago a proveedores'
            });
            this.$swal.showLoading();
            const self = this;
            const req = {
                page: 1,
                per_page: 10
            }

            axios.post('/api/getSupplierPayments', req, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(response => {
                console.log(response.data)
                if (response.status == 200) {

                    self.items = response.data.data
                    console.log(self.items)
                    //self.clientesSelect = response.data.clienteEmpresa;
                    this.$swal.close();
                } else {
                    self.clients = [];
                }
            }).catch(function (error) {

                if (error.response.statusText == 'Unauthorized') {
                    localStorage.removeItem('accessToken');
                    window.location.href = './../../api/auth/logout'
                }


            });

        }
    },
};
</script>

<style lang="css">
@import 'bootstrap';
@import 'datatables.net-bs5';



.cont-proveedor {
    height: 300px;
    overflow-y: scroll;
}
</style>